import React from 'react';
import styles from '../styles/scss/pages/page.module.scss'
import { sortBy as _sortBy } from "lodash"
import { Link } from "gatsby"
import NewLayout from '../layout/newLayout';

const Page = ({ pageContext }) => {
  return (
    <NewLayout>
      <div className='d-flex flex-column flex-fill'>
        <div className={styles.containerColumn}>
          <h2>Pages</h2>
          {
            _sortBy(pageContext.pages, 'title').map(p => (
              <div key={p.id}><Link to={p.path}>{p.title}</Link></div>
            ))
          }
          <h2>Products</h2>
          {
            _sortBy(pageContext.products, 'displayName').map(p => (
              <div key={p.id}><Link to={"/products/" + p.slug + "/"}>{p.displayName}</Link></div>
            ))
          }
          <h2>Articles</h2>
          {
            _sortBy(pageContext.articles, 'title').map(p => (
              <div key={p.id}><Link to={"/article/" + p.slug + "/"}>{p.title}</Link></div>
            ))
          }
        </div>
      </div>
    </NewLayout>
  )
}

export default Page;
